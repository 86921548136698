<script>
import Layout from '../layout/Layout'
import Mixin from './mixin'

export default {
  name: 'ValidadeDocumentosMain',
  provide: function () {
    return {
      mainLayout: this
    }
  },
  mixins: [Mixin],
  components: {
    Layout
  },
  data () {
    return {
      activeMenu: 'pessoas'
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Validade de Documentos',
    name: 'Validade de Documentos'
  }
}
</script>

<template>
  <layout no-menu class="pessoa" no-title pad hfull>
    <router-view />
  </layout>
</template>

<style lang="stylus" src="./assets/default.styl"></style>
